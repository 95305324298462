<template>
  <div 
    id="map"
    ref="map-root"
    class="w-full h-screen"
  />
</template>

<script>
  // dirty fix for canvas in ol/render/canvas/ExecutorGroup
  // this.hitDetectionContext_ = createCanvasContext2D(contextSize, contextSize, null, { willReadFrequently : true });
  import View from 'ol/View'
  //import Feature from 'ol/Feature'
  import Map from 'ol/Map'
  import TileLayer from 'ol/layer/Tile'
  import OSM from 'ol/source/OSM'
  import VectorLayer from 'ol/layer/Vector'
  import Vector from 'ol/source/Vector'
  import Rotate from 'ol/control/Rotate'
  import * as proj from 'ol/proj';
  //import GeoJSON from 'ol/format/GeoJSON'
  import {Style} from 'ol/style';
  import Feature from 'ol/Feature'
  import Point from 'ol/geom/Point'
  import Icon from 'ol/style/Icon'
  import Fill from 'ol/style/Fill'
  import Text from 'ol/style/Text'
  import Stroke from 'ol/style/Stroke'
  import 'ol/ol.css'
  import Colorize from 'ol-ext/filter/Colorize'
  import '../assets/js/sunset.js'

  export default {
    name: 'MapContainer',
    components: {},
    props: {
      demos: {
          type: Array,
          default() {
              return []
          }
      },
      reallatitude: {
          type: Number,
          default: null
      },
      reallongitude: {
          type: Number,
          default: null
      },
      repaint: {
          type: Number,
          default: null
      },
    },
    emits: ["selectDemo"],
    data: () => ({
      olMap: null,
      vectorLayer: null,
      tileLayer: null,
      selectDemo: null,
      selectFeature: null,
      filter: null
    }),
    watch: {
      selectDemo(value) {
        this.$emit('selectDemo', value)
      },
      demos(value) {
        this.updateSource(value)
      },
      repaint(value) {
        if (value > 0) {
          setTimeout(() => { this.olMap.updateSize(); });
        }
      },
      '$userTheme.value': {
        handler: function () {
          this.updateTheme();
        }, deep: true
      },
      '$selectDemo.value': {
        handler: function () {
          this.updateSelectDemoAfterClickOnList();
        }, deep: true
      }
    },
    mounted() {
      // console.log('map mounted');
      this.vectorLayer =  new VectorLayer({
        source: new Vector()
      });
      this.tileLayer = new TileLayer({
        source: new OSM(),
        visible: true
      }),
      this.olMap = new Map({
        target: this.$refs['map-root'],
        layers: [
          this.tileLayer,
          this.vectorLayer
        ],
        view: new View({
          center: [0, 0],
          zoom: 2
        }),
      });
      let olMap = this.olMap;
      olMap.getControls().forEach(function(control) {
        if (control instanceof Rotate) {
          olMap.removeControl(control);
        }
      }, this);
      //var enhance = new Colorize({ operation:'enhance', value: 1 });
      //this.tileLayer.addFilter(enhance);

      // Custom filter
      this.filter = new Colorize();
      this.tileLayer.addFilter(this.filter);

      this.updateTheme()

      this.olMap.on(['pointermove', 'click'], (event) => {
        if (event.dragging) return;

        let hoveredFeatures = this.olMap.forEachFeatureAtPixel(event.pixel, (feature) => feature);
        if (hoveredFeatures !== undefined) {
          this.olMap.getTargetElement().style.cursor = 'pointer';
          this.demos.forEach((demo) => {
            if (hoveredFeatures.getId() === demo.id && (this.selectDemo == null || this.selectDemo.id != demo.id)) {
              this.repaintFlags(this, demo, hoveredFeatures, false);
            }
            return true;
          });
        } else {
          this.olMap.getTargetElement().style.cursor = '';
        }
      });
    },
    methods: {
      updateSelectDemoAfterClickOnList() {        
        let vectorSource = this.vectorLayer.getSource();
        let newDemo = this.$selectDemo.value;
        const that = this;
        vectorSource.getFeatures().forEach(function (feature) {
          if (feature.getId() === newDemo.id && (that.selectDemo == null || that.selectDemo.id != newDemo.id)) {
            that.repaintFlags(that, newDemo, feature, true);
          }
        });
      },
      repaintFlags(that, demo, newFeature, setCenter) {
          // console.log('repaintFlags')
          if (that.selectFeature != null && that.selectDemo != null) {
            const oldColor = that.selectDemo.color;
            const route = that.selectDemo.route_desc;
            let icon = function() {
              let styles = [];
              styles.push(that.getIconStyle(oldColor, 1.25, 0.85, route));
              return styles
            }
            that.selectFeature.setStyle(icon);
          }
          that.selectDemo = demo;
          that.selectFeature = newFeature;
          let icon = function(feature, resolution) {
            let styles = [];
            let startDate  = demo.start_date;
            let startTime = demo.start_time;
            let endTime = demo.end_time;
            styles.push(that.getIconStyle(demo.color, 2, 1, demo.route_desc));
            if (resolution <= 10) {
              if (demo.count >= 2) {
                if (demo.topic_desc.includes('täglich')) {
                  startDate = that.isEnglish() ? 'daily' : 'täglich';
                } else if (demo.topic_desc.includes('jeweils')) {
                  startDate = demo.topic_desc.substring(demo.topic_desc.indexOf('jeweils')).replace(')', '')
                  if ( that.isEnglish()) {
                    startDate = 'every ' + that.getEnglishDay(startDate.replace('jeweils ', ''));
                  }
                } else {
                  startDate = 'ab ' + startDate;
                }
              }
              if (startTime == '00:00' && endTime == '00:00') {
                startTime = that.isEnglish() ? 'all day' : 'ganztägig';
                endTime = '';
              } else {
                startTime = startTime.replace(":00", "");
                endTime = endTime.replace(":00", "");
                if (that.isEnglish()) {
                  startTime = demo.start_time_english;
                  endTime = demo.end_time_english;
                } else {
                  endTime = endTime ? endTime + ' Uhr' : '';
                }
              }

              let text = startDate + "\n"
                + startTime
                + (endTime ? "-" : "")
                + endTime
              styles.push(that.getIconText(text, 20, -100, demo.color)); 
            }
            return styles
          }                                    
             
          newFeature.setStyle(icon);
          if (setCenter && demo.lat && demo.lon) {
            this.centerMap(demo.lat, demo.lon);
          }
      },
      centerMap(lat, long) {
        this.olMap.getView().setCenter(proj.transform([long, lat], 'EPSG:4326', 'EPSG:3857'));
        this.olMap.getView().setZoom(15);
      },
      isEnglish() {
          return this.$currentLanguage.value == 'en';   
      },
      getEnglishDay(day) {
        switch (day) { // hour
          case 'Mo.':
            return 'Mon.'
          case 'Di.':
            return 'Tue.'
          case 'Mi.':
            return 'Wed.'
          case 'Do.':
            return 'Thu.'
          case 'Fr.':
            return 'Fri'
          case 'Sa.':
            return 'Sat.'
          case 'So.':
            return 'Sun.'                
          default:
            return day;
        }   
      },
      getIconStyle(color, scale = 1.5, opacity = 0.85, left) {
        if (this.$userTheme.value === 'dark-theme') {
          if (color == 'black') {
            color = 'white';
          }
          // if (color == 'blue') {
          //   color = 'lightblue';
          // }
          // if (color == 'green') {
          //   color = 'lightgreen';
          // }
        }
        let svg = '<?xml version="1.0" encoding="utf-8"?>' +
          `<svg id="flag" width="35px" height="35px" role="img" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve" fill-opacity="${opacity}" fill="${color === 'rainbow' ? 'url(#rainbow)' : color}">` +
          `<defs>
            <linearGradient id="rainbow" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0" stop-color="red"/>
              <stop offset="0.2857" stop-color="#ff0"/>
              <stop offset="0.4286" stop-color="#0f0"/>
              <stop offset="0.5714" stop-color="cyan"/>
              <stop offset="0.7142" stop-color="blue"/>
              <stop offset="0.8571" stop-color="#f0f"/>
              <stop offset="1" stop-color="violett"/>
            </linearGradient>
          </defs>` +
          `<g transform="${left ? 'scale(1,-1) translate(0,-1000)' : ''}"><path d="M738.2,93.8c-73.2,0-138.9-17.6-183.8-41.9C509.6,27.5,443.9,10,370.7,10c-73.2,0-138.8,30.1-183.8,54.4v460.7c44.9-24.4,110.5-54.4,183.8-54.4c73.2,0,138.9,17.6,183.7,41.9c44.9,24.4,110.5,41.9,183.8,41.9c73.2,0,138.9-32.2,183.8-56.6V37.2C877.1,61.6,811.4,93.8,738.2,93.8z M118.9,10c-22.6,0-40.8,18.3-40.8,40.8v898.3c0,22.5,18.3,40.8,40.8,40.8c22.6,0,40.8-18.3,40.8-40.8V50.8C159.7,28.3,141.5,10,118.9,10z"/></g>` +
          '</svg>';
        let anchorY = left ? 0 : 35;
        let rotation = left ? 3.1415926 : 0;
        return new Style({
          image: new Icon({
            opacity: 1,
            src: 'data:image/svg+xml,' + encodeURIComponent(svg),
            scale: scale,
            anchor: [0, anchorY],
            anchorXUnits: "pixels",
            anchorYUnits: "pixels",
            offset: [0,0],
            offsetOrigin: 'bottom',
            //offsetOrigin: 'bottom-left',
            rotation: rotation,
          }),
          overflow: true,
        })
      },
      getIconText(text, offsetTextX, offsetTextY, color, strokeWidth = 10) {
        return new Style({
          text: new Text({
            font: "small-caps 20px arial",
            text: text,
            scale: 1,
            offsetX: offsetTextX,
            offsetY: offsetTextY,
            fill: new Fill({
              color: color
            }),
            stroke: new Stroke({
              color: color === "#fff" ? "#000" : "#fff",
              width: strokeWidth
            })
          })
        });
      },
      updateTheme() {
        if (this.$userTheme.value === 'dark-theme') {
          this.filter.setFilter('invert');
        } else {
          this.filter.setFilter('sepia');
        }
        this.updateSource(this.demos);
      },
      updateSource(demos) {
        // console.log("updateSource " + demos);
        let vectorSource = this.vectorLayer.getSource();
        vectorSource.getFeatures().forEach(function (feature) {
          vectorSource.removeFeature(feature);
        });
        const that = this;
        let offset = 0.005;
        let allLons = [];
        let allLats = [];
        demos.forEach((demo/*, index*/) => {
          if (!demo.lon || !demo.lat || !demo.topic || !demo.start_date) {
            return;
          }
          // Gleiche Demos erkennen
          let count = 0;
          while (allLons.includes(demo.lon) && allLats.includes(demo.lat) && count < 10) {
            offset *= -0.15;
            demo.lon = offset + parseFloat(demo.lon) + 0.0001;
            count++;
          }
          allLons.unshift(demo.lon);
          allLats.unshift(demo.lat);
          let coordinates = proj.transform([demo.lon, demo.lat], 'EPSG:4326', 'EPSG:3857')
          let marker = new Feature(new Point(coordinates));
          const route = demo.route_desc;
          let icon = function() {
            let styles = [];
            styles.push(that.getIconStyle(demo.color, 1.25, 0.85, route));
            return styles
          }
          marker.setStyle(icon)
          marker.setId(demo.id);
          vectorSource.addFeature(marker)
        });

        if (demos.length == 1) {
          this.centerMap(allLats[0], allLons[0]);
          return;
        }

        if (demos.length > 0) {
          let extent = vectorSource.getExtent();
          // extent[0] += 20000; // oestliche laenge start (longitude)
          extent[1] -= 1000; // noerdliche breite start (latitude)
          // extent[2] -= 20000; // oestliche laenge ende (longitude)
          extent[3] -= 1000; // noerdliche breite ende (latitude)
          this.olMap.getView().fit(extent, { minResolution: 40 })
          return;
        }

        let defaultExtend = null;
        let offsetExtendLng = 0.08; 
        let offsetExtendLat = offsetExtendLng;
        if (this.reallongitude && this.reallatitude) {
          defaultExtend = proj.transformExtent(
            [
              this.reallongitude - offsetExtendLng,
              this.reallatitude - offsetExtendLat,
              this.reallongitude + offsetExtendLng,
              this.reallatitude + offsetExtendLat
            ],
            'EPSG:4326', 'EPSG:3857'
          )
        }
        
        if (defaultExtend == null) {
          // Initial screen clipping
          var minx = 10.0;
          var maxx = 12.0;
          var miny  = 42.0;
          var maxy  = 54.5;
          // defaultExtend = proj.transformExtent([10.0, 42.0, 12.0, 54.5], 'EPSG:4326', 'EPSG:3857');
          defaultExtend = proj.transformExtent([minx, miny, maxx, maxy], 'EPSG:4326', 'EPSG:3857');
        }
        this.olMap.getView().fit(defaultExtend, {
          size: this.olMap.getSize(),
          padding: [5, 5, 5, 5]
        });
      }
    }
  }
</script>
<style scoped>
#map {
  color: springgreen !important;
  height: 83vh;
  /* min-width: 100%; */
}
canvas {
  width: 100% !important;
}
</style>

